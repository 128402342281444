import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React, { useEffect, useState } from "react"
import Layout from "../../components/_App/layout"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import * as convertStyled from "../../assets/css/convert-case.module.css"
import {
  generatePascalCase,
  handleCopy,
  handleDownload
} from "../../helpers/covert-case"
import CounterText from "../../helpers/CounterText"
import DownloadAndCopyButtons from "../../helpers/buttons"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import CopiedMessage from "../../helpers/CopiedMessage"

const parent = [
  {
    name: "Tools",
    link: "tools"
  }
]

function PascalCaseText() {
  const [textFieldValue, setTextFieldValue] = useState("")
  const [previewValue, setPreviewValue] = useState("")
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [message, setMessage] = useState("");

  function handleTextField(e) {
    setTextFieldValue(e.target.value)
    setPreviewValue(generatePascalCase(e.target.value))
  }

  useEffect(() => {
    setIsInputEmpty(textFieldValue === "");
  }, [textFieldValue])

  const showMessage = (msg) => {
    setMessage(msg);
  };

  return (
    <Layout>
      <Seo title="Pascal Case Text Conversion Tool" />
      <Navbar />
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>Pascal Case Generator</h1>
            <div className="bar"></div>
          </div>
          <Breadcrumbs pageTitle="Pascal Case Generator" parent={parent} />
          <div className="row align-items-center">
            <div className="row">
              <div className="col-lg-6">
                <textarea
                  name="" value={textFieldValue} onChange={(e) => handleTextField(e)} className={convertStyled.text_field} id="" cols="30" rows="10" />
                <CounterText text={textFieldValue} />
              </div>
              <div className="col-lg-6">
                <textarea
                  name="" value={previewValue}  className={`${convertStyled.text_field}`} id="" cols="30" rows="10" />
                <DownloadAndCopyButtons previewValue={previewValue} disabled={isInputEmpty}  showMessage={showMessage}/>  {/*This is Button's code-------------------------->*/}
                {message && (<CopiedMessage message={message} />)} {/* Display the message-----------------------> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default PascalCaseText
